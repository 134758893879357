@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700;800;900&display=swap');

/*
  This hack avoids Chrome terrible yellow color on autofilled inputs

  Source: http://webagility.com/posts/the-ultimate-list-of-hacks-for-chromes-forced-yellow-background-on-autocompleted-inputs
*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input[data-com-onepassword-filled='light'],
select[data-com-onepassword-filled='light'],
input[data-com-onepassword-filled='dark'],
select[data-com-onepassword-filled='dark'] {
  -webkit-transition-delay: 99999s;
  transition-delay: 99999s;
}

::selection {
  color: #fff;
  background: hsl(227, 42%, 51%); /* WebKit/Blink Browsers */
}

::-moz-selection {
  color: #fff;
  background: hsl(227, 42%, 51%); /* Gecko Browsers */
}

.update-notification {
  background-color: hsl(227, 42%, 51%);
  color: #fff;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.8em 1.2em;
  text-decoration: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

dl,
ul,
ol {
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
